export const RESET_STATE = "CRUD_RESET_STATE";
export const CURRENT_ITEM = "CRUD_CURRENT_ITEM";
export const CURRENT_ITEMS = "CRUD_CURRENT_ITEMS";

export const REQUEST_LOADING = "CRUD_REQUEST_LOADING";
export const REQUEST_SUCCESS = "CRUD_REQUEST_SUCCESS";
export const REQUEST_FAILED = "CRUD_REQUEST_FAILED";



//excel types
export const EXCEL_UPLOADING_REQUEST_LOADING = "EXCEL_UPLOADING_REQUEST_LOADING";
export const EXCEL_UPLOADING_REQUEST_SUCCESS = "EXCEL_UPLOADING_REQUEST_SUCCESS";
export const EXCEL_UPLOADING_REQUEST_FAILED = "EXCEL_UPLOADING_REQUEST_FAILED";


export const GET_UPLOADED_FILES_REQUEST_LOADING = 'GET_UPLOADED_FILES_REQUEST_LOADING'
export const GET_UPLOADED_FILES_REQUEST_SUCCESS = 'GET_UPLOADED_FILES_REQUEST_SUCCESS'
export const GET_UPLOADED_FILES_REQUEST_FAILED = 'GET_UPLOADED_FILES_REQUEST_FAILED'


export const CURRENT_ACTION = "CRUD_CURRENT_ACTION";
export const RESET_ACTION = "CRUD_RESET_ACTION";
