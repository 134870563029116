import { ACCESS_TOKEN_NAME } from "@/config/serverApiConfig";

import axios from "axios";
import errorHandler from "@/request/errorHandler";
import successHandler from "@/request/successHandler";
import storePersist from "@/redux/storePersist";
import store from "@/redux/store";
import { logout as logoutAction } from "@/redux/auth/actions";

import { getCookie, setCookie, deleteCookie } from "./cookie";
import {
  FUSIONAUTH_URL,
  FUSIONAUTH_APPLICATION_ID,
  FUSIONAUTH_API_KEY,
} from "../config/serverApiConfig";

export const login = async (loginAdminData) => {
  try {
    const response = await axios.post(
      `${FUSIONAUTH_URL}/api/login`,
      {
        ...loginAdminData,
        applicationId: FUSIONAUTH_APPLICATION_ID,
        generateRefreshToken: true
      },
      {
        headers: {
          Authorization: FUSIONAUTH_API_KEY,
        },
      }
    );
    let isSuperAdmin = false;
    const registrations = response?.data?.user?.registrations;
    // loop through registrations and check for tfm role
    for (let i = 0; i < registrations?.length; i++) {
      const roles = registrations[i]?.roles;
      if (roles?.includes("super-admin")) {
        isSuperAdmin = true;
        break;
      }
    }
    if (isSuperAdmin) {
      token.set({
        token: response?.data?.token,
        refreshToken: response?.data?.refreshToken,
        refreshTokenId: response?.data?.refreshTokenId,
        email: response?.data?.user?.email,
      });
      return successHandler(response);
    } else {
      return errorHandler({
        response: {
          status: 401,
          data: {
            message: "You don't have a permission to login",
          },
        },
      });
    }
  } catch (error) {
    return errorHandler(error);
  }
};

export const refreshToken = async (refreshToken) => {
  try {
    const { data } = await axios.post(
      `${FUSIONAUTH_URL}/api/jwt/refresh`,
      {
        refreshToken
      },
      {
        headers: {
          Authorization: FUSIONAUTH_API_KEY,
        },
      }
    );
    if (data) {
      token.set({
        token: data?.token,
        refreshToken: data?.refreshToken,
        refreshTokenId: data?.refreshTokenId,
      });
      return data?.token;
    }
  } catch (error) {
    errorHandler({
      response: {
        status: 400,
        data: {
          message: "An error occurred while refreshing the session token, please try to login again!",
        },
      },
    });
    store.dispatch(logoutAction())
  }
};
export const logout = async () => {
  token.remove();
  storePersist.clear();
};

export const token = {
  get: () => {
    return getCookie(ACCESS_TOKEN_NAME);
  },
  set: (token) => {
    return setCookie(ACCESS_TOKEN_NAME, token);
  },
  remove: () => {
    return deleteCookie(ACCESS_TOKEN_NAME);
  },
};
